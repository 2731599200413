import '../vendors/datepicker.esm.js';
/*eslint-disable */
$.fn.center = function () {
    this.css("position", "absolute");
    this.css("top", Math.max(0, $(window).height() - $(this).outerHeight() / 2 + $(window).scrollTop()) + "px");
    this.css("left", Math.max(0, $(window).width() - $(this).outerWidth() / 2 + $(window).scrollLeft()) + "px");
    return this;
};


$.fn.gotoAnchor = function (anchor) {
    location.href = '#' + this.selector;
};
var spinnerVisible = false;

function collision($div1, $div2) {
    var x1 = $div1.offset().left;
    var y1 = $div1.offset().top;
    var h1 = $div1.outerHeight(true);
    var w1 = $div1.outerWidth(true);
    var b1 = y1 + h1;
    var r1 = x1 + w1;
    var x2 = $div2.offset().left;
    var y2 = $div2.offset().top;
    var h2 = $div2.outerHeight(true);
    var w2 = $div2.outerWidth(true);
    var b2 = y2 + h2;
    var r2 = x2 + w2;

    if (b1 < y2 || y1 > b2 || r1 < x2 || x1 > r2) return false;
    return true;
}

function showPager() {
    if ($('.panel:visible').length >= 48) {
        $('.pagination').show();
    } else {
        $('.pagination').hide();
    }
}

window.unipolSharedNamespace = {
    showProgress,
    hideProgress,
    setSelectedCityReloadPage,
    gtmTracking,
    sendGTM,
}

function showProgress() {
    if (!spinnerVisible) {
        $(".js-spinner").addClass('spinner-show');
        spinnerVisible = true;
    }
}

function hideProgress() {
    if (spinnerVisible) {
        var spinner = $(".js-spinner");
        spinner.removeClass('spinner-show');
        spinnerVisible = false;
    }
}

function scrollToTop() {
    $('html, body').animate({ scrollTop: '0px' }, 300);
}

function _alert(options) {
    if (!options) { options = {}; }

    var show = function (el, text) {
        if (text) { el.html(text); el.show(); } else { el.hide(); }
    };

    var ok = options.ok ? options.ok : 'OK';
    var title = options.title;
    var text = options.text;
    var dialog = $('#alert-dialog');
    var header = dialog.find('.modal-header');
    var footer = dialog.find('.modal-footer');

    show(dialog.find('.modal-body'), text);
    show(dialog.find('.modal-header h3'), title);
    footer.find('.btn-warning').unbind('click').html(ok);
    dialog.modal('show');
}

function _confirm(options) {
    if (!options) { options = {}; }

    var show = function (el, text) {
        if (text) { el.html(text); el.show(); } else { el.hide(); }
    };

    var url = options.url ? options.url : '';
    var data = options.data ? options.data : '';
    var ok = options.ok ? options.ok : 'OK';
    var cancel = options.cancel ? options.cancel : 'Cancel';
    var title = options.title;
    var text = options.text;
    var dialog = $('#confirm-dialog');
    var header = dialog.find('.modal-header');
    var footer = dialog.find('.modal-footer');
    var method = 'POST';
    var dataType = 'json';


    //debugger;
    if (typeof options.method !== 'undefined') {
        method = options.method;
    }

    if (typeof options.dataType !== 'undefined') {
        dataType = options.dataType;
    }

    show(dialog.find('.modal-body'), text);
    show(dialog.find('.modal-title'), title);
    footer.find('.btn-ok').unbind('click').html(ok);
    footer.find('.btn-cancel').unbind('click').html(cancel);
    dialog.modal('show');

    var $deferred = $.Deferred();
    var is_done = false;

    footer.find('.btn-ok').on('click', function (e) {
        e.preventDefault();
        is_done = true;
        dialog.modal('hide');
        if (url && url !== '') {
            $.ajax({
                url: url,
                data: data,
                type: method,
                dataType: dataType
            }).done(function (result) {
                $deferred.resolve(result);
                hideProgress();
            }).fail(function (xhr, ajaxOptions, error) {
                $deferred.reject();
                hideProgress();
            });
        } else {
            $deferred.resolve();
        }
    });

    dialog.on('hide', function () {
        showProgress();
        if (!is_done) {
            $deferred.reject();
        }
    });

    return $deferred.promise();
}

function setSelectedCity(city) {
    $.get('/my-unipol/setselectedcity/' + city, function (data) {
        //Do something
    });
}

function setSelectedCityReloadPage(city) {
    $.get('/my-unipol/setselectedcity/' + city, function (data) {
        location.reload();
    });
}

function _notification(options) {
    if (!options) { options = {}; }
    var show = function (el, text) {
        if (text) { el.html(text); el.show(); } else { el.hide(); }
    };

    var title = options.title;
    var text = options.text;
    var dialog = $('#notification-dialog');
    var header = dialog.find('.modal-header');
    var footer = dialog.find('.modal-footer');

    show(dialog.find('.modal-body'), text);
    show(dialog.find('.modal-header h3'), title);
    dialog.modal('show');
}

window.modalsFunctions = {
    _alert,
    _confirm,
    _notification
}

function hideEmptyCols(table) {

    var rows = $("tr", table).length - 1;
    var numCols = $("th", table).length;
    for (var i = 1; i <= numCols; i++) {

        if ($("parent:empty", $("td:nth-child(" + i + ")", table)).length === rows) {
            $("td:nth-child(" + i + ")", table).hide(); //hide <td>'s
            $("th:nth-child(" + i + ")", table).hide(); //hide header <th>
        }
    }
}

//Refactor this later
function sortColumn(sortBy, sortDirection, source) {

    var currentSortColumn = "ItemID";
    var currentSortOrder = "asc";

    //loadEventAttendeeList
    if (source === "events") {
        $.get('/my-unipol/setsortorder/' + sortBy + '/' + sortDirection, function (data) {
            currentSortColumn = "Name";
            currentSortOrder = "asc";

            loadEventAttendeeList();
        });
    } else {
        $.get('/my-unipol/setsortorder/' + sortBy + '/' + sortDirection, function (data) {
            advertlist();
        });
    }
}

function setCurrentSort() {
    var currentSortColumn = "ItemID";
    var currentSortOrder = "asc";

    $.get('/my-unipol/getCurrentSort/', function (data) {
        currentSortColumn = data.currentSortColumn;
        currentSortOrder = data.currentSortOrder;

        var $currentSortLink = $('thead.sortable').find("[data-sort-column='" + currentSortColumn + "']");
        //Set icon
        $('thead.sortable a').removeClass("sort");
        $currentSortLink.addClass("sort");

        //set direction
        if (currentSortOrder === "desc") {
            $currentSortLink.addClass("desc");
        } else {
            $currentSortLink.removeClass("desc");
        }
    });
}

function getParameterByName(name) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function currentSortOrder() {
    $.get('/my-unipol/currentSortOrder/', function (data) {
        if (data !== null) {
            return data;
        } else {
            return 'ItemID';
        }
    });
}

function doCascade(delay) {
    // Initiate an array
    var items = ["First", "Second", "Third", "Fourth", "Fifth"];
    // Loop through it with jQuery.each()
    $(items).each(function (i, text) {
        // Create a new DIV.
        var item = $('<div></div>');

        // Give it a class for styling
        $(item).addClass('item');

        // Add our text from the array.
        $(item).text(text);

        // Make it so it doesn't display
        // when it's added to the DOM.
        $(item).css({
            'display': 'none'
        });

        // Tell jQuery to delay the fadeIn by i * delay,
        // (no. times loop has iterated) * (delay in ms)
        $(item).delay(i * delay).fadeIn();

        // Finally, append it to a wrapper.
        // This is just an empty div, somewhere
        // to put the result.
        $('#cascade-wrap').append(item);
    });
}

function GetBaseUrl() {
    try {

        var l = window.location;
        var baseURL = "//" + l.host + "/" + l.pathname.split('/')[0];
        if (document.location.hostname === "localhost") {
            //var baseURL = l.protocol + "//" + l.host + "/" + l.pathname.split('/')[1];
            baseURL = "//" + l.host + "/" + l.pathname.split('/')[1];
            return baseURL;
        }

        return baseURL;

    }
    catch (arg) {
        return null;
    }
}

function selectedCity() {
    $.get('/my-unipol/selectedcity', function (data) {
        return data;
    });
}

function equalHeights() {
    //Make elements with a class of equalize the same height, (matches the tallest.)
    var boxes = $('.equalize');

    if (boxes.length > 3) {
        equalHeight(boxes);
    }
}

function equalHeight(group) {
    var tallest = 0;
    group.each(function () {
        var thisHeight = $(this).height();
        if (thisHeight > tallest) {
            tallest = thisHeight;
        }
    });
    group.height(tallest);
}

function currentSection() {
    var retCurrentSection;
    var pathArray = window.location.pathname.split('/');
    var pathSectionLower = "";

    if (pathArray[1].toLowerCase() === "unipol") {
        //MATCHES - //Unipol/section   //Unipol/{city/all}/Section
        pathSectionLower = pathArray[2].toLowerCase();
        retCurrentSection = pathSectionLower === "all" || pathSectionLower === "leeds" || pathSectionLower === "bradford" || pathSectionLower === "nottingham" ? pathArray[3] : pathArray[2];
    }
    else {
        //MATCHES - //section   //{city/all}/Section
        pathSectionLower = pathArray[1].toLowerCase();
        retCurrentSection = pathSectionLower === "all" || pathSectionLower === "leeds" || pathSectionLower === "bradford" || pathSectionLower === "nottingham" ? pathArray[2] : pathArray[1];
    }

    return retCurrentSection;
}

function LoadTwitterFeed() {
    $.ajax({
        url: "https://api.twitter.com/1/statuses/user_timeline.json?screen_name=unipol&count=3&include_entities=true&include_rts=true&callback=?",
        dataType: "jsonp",
        jsonpCallback: "displayFeeds"
    });
}

export function BindElements() {
    if ($('.datepicker').length) {
        $('.datepicker').datepicker({ "format": "dd/M/yyyy" });
    }

    if ( $('[rel="datetime"]').length) {
        $('[rel="datetime"]').datepicker({
            autoClose: true,
            dateFormat: "dd/M/yyyy",
            changeYear: true,
            changeMonth: true,
            beforeShow: function (input, inst) {
                $(inst).css("z-index", "1060");
            }
        });
    }
    $('input[rel="datetime"]').keypress(function (e) {
        return false;
    });

    $('input[rel="datetime"]').keydown(function (e) {
        if (e.keyCode !== 46 && e.keyCode !== 8)
            return false;

        $(e.currentTarget).val("");
        e.stopPropagation();
        e.preventDefault();
        return false;
    });


    bindToolTips();

    //bindCheckBoxes();

    //equalHeights();


    //Show additional _Details field for selected checkboxes.
    RefreshCheckboxDetails();
    RefreshDropdownDetails();
}

function bindKenticoFormControls() {
    $("form input:checkbox").addClass("styled-checkbox");
    bindCheckBoxes();
}

function bindCheckBoxes() {

    //Checkbox Toggle Binding
    $('.unipol-style-checkbox, .unipol-style-checkbox-blue , .unipol-style-checkbox-green, .unipol-style-checkbox-purple').each(function () {

        var thisID = $(this).attr('id');
        var thisClass = $(this).attr('class');
        var setClass;

        switch (thisClass) {

            case "unipol-style-checkbox":
                setClass = "unipol-style";
                break;
            case "unipol-style-checkbox-blue":
                setClass = "unipol-style-blue";
                break;
            case "unipol-style-checkbox-green":
                setClass = "unipol-style-green";
                break;
            case "unipol-style-checkbox-purple":
                setClass = "unipol-style-purple";
                break;
        }
        $(this).addClass('hidden');


        if ($(this)[0].checked === true)
            $(this).after('<div class="' + setClass + ' on" rel="' + thisID + '">&nbsp;</div>');
        else
            $(this).after('<div class="' + setClass + ' off" rel="' + thisID + '">&nbsp;</div>');

    });

}

function RefreshCheckboxDetails() {

    //Shows _Details areas for checked checkboxes

    $(".styled-checkbox").each(function () {

        var $this = $(this)[0];
        var checkboxID = '#' + $this.id;

        if ($(checkboxID + "_Details").length > 0) {
            if ($this.checked === true) {
                $(checkboxID + "_Details").slideDown('fast');
            } else {
                $(checkboxID + "_Details").hide();
            }
        }

        //if ($this.id !== undefined && $this.id.indexOf('NeedsLicense') > 1) {
        //    if ($this.checked === false) {
        //        $(checkboxID + "_NoLicense_Details").slideDown('fast');
        //    } else {
        //        $(checkboxID + "_NoLicense_Details").hide();
        //    }
        //}

        if ($this.id !== undefined && $this.id.indexOf('HasLicense') > 1) {
            if ($this.checked) {
                $(checkboxID + "_NoLicense_Details").slideUp('fast');
            } else {
                $(checkboxID + "_NoLicense_Details").slideDown('fast');
            }
        }

    });

    //Checkbox Toggle Binding
    //$('div[rel$=_WashingMachine]').click(function () {
    //	thisID = $(this).attr('id');
    //	thisClass = $(this).attr('class');
    //	thisRel = $(this).attr('rel');
    //	listIndex = thisRel.substring(thisRel.indexOf('_') + 1, thisRel.lastIndexOf('_') - 1);
    //	var checkboxUI = $("div[rel=PropertyList_" + listIndex + "__WashingMachinePoint]");
    //	var checkboxControl = $("#PropertyList_" + listIndex + "__WashingMachinePoint");

    //	if (thisClass.indexOf('off') > 1) {
    //		checkboxControl[0].checked = true;
    //		checkboxUI.addClass('on').removeClass('off');
    //	}
    //});

    //$('div[rel$=_WasherDryer]').click(function () {
    //	thisID = $(this).attr('id');
    //	thisClass = $(this).attr('class');
    //	thisRel = $(this).attr('rel');
    //	listIndex = thisRel.substring(thisRel.indexOf('_') + 1, thisRel.lastIndexOf('_') - 1);
    //	var checkboxUI = $("div[rel=PropertyList_" + listIndex + "__WashingMachinePoint]");
    //	var checkboxControl = $("#PropertyList_" + listIndex + "__WashingMachinePoint");

    //	if (thisClass.indexOf('off') > 1) {
    //		checkboxControl[0].checked = true;
    //		checkboxUI.addClass('on').removeClass('off');
    //	}
    //});

}

function RefreshDropdownDetails() {
    $('select.has-dependencies').each(function () {

        var ids = $(this).data("display-ids");
        var selectedId = $(this).val();

        var dropdownID = "#" + $(this).attr('id');

        if (selectedId == ids) {
            $(dropdownID + "_Details").slideDown('fast');
        } else {
            $(dropdownID + "_Details").hide();
        }
    });

}

window.unipolLastResortNamespace = {
    bindToolTips,
}

function bindToolTips() {

    var targets = '[rel~=tooltip]',
        target = false,
        tooltip = false,
        title = false,
        tip = null;

    $(document).on('mouseenter', targets, function (e) {
        target = $(this);
        tip = target.attr('title');
        tooltip = $('<div id="tooltip"></div>');

        if (!tip || tip === '')
            return false;

        target.removeAttr('title');
        tooltip.css('opacity', 0)
            .html(tip)
            .appendTo('body');

        var init_tooltip = function () {
            if ($(window).width() < tooltip.outerWidth() * 1.5)
                tooltip.css('max-width', $(window).width() / 2);
            else
                tooltip.css('max-width', 340);

            var pos_left = target.offset().left + target.outerWidth() / 2 - tooltip.outerWidth() / 2,
                pos_top = target.offset().top - tooltip.outerHeight() - 20;

            if (pos_left < 0) {
                pos_left = target.offset().left + target.outerWidth() / 2 - 20;
                tooltip.addClass('left');
            }
            else
                tooltip.removeClass('left');

            if (pos_left + tooltip.outerWidth() > $(window).width()) {
                pos_left = target.offset().left - tooltip.outerWidth() + target.outerWidth() / 2 + 20;
                tooltip.addClass('right');
            }
            else
                tooltip.removeClass('right');

            if (pos_top < 0) {
                pos_top = target.offset().top + target.outerHeight();
                tooltip.addClass('top');
            }
            else
                tooltip.removeClass('top');

            tooltip.css({ left: pos_left, top: pos_top })
                .animate({ top: '+=10', opacity: 1 }, 50);
        };

        init_tooltip();
        $(window).resize(init_tooltip);

        var remove_tooltip = function () {
            tooltip.animate({ top: '-=10', opacity: 0 }, 50, function () {
                $(this).remove();
            });

            target.attr('title', tip);
        };

        $(document).on('mouseleave', targets, remove_tooltip);
        $(document).on('click', '#tooltip', remove_tooltip);
    });
}


/* right panel sticky scroll ****************************************************/
function resetRightPanelStickyScroll(name, rightPanel, leftPanel) {

    var scrollEvent = 'scroll.' + name;

    $(rightPanel).parent().height('auto');
    $(leftPanel).parent().height('auto');

    $(window).off(scrollEvent);

    $(rightPanel).css({
        position: '',
        top: '',
        left: '',
        bottom: '',
        width: ''
    });
}

function updateRightPanelStickyScroll(rightPanel, topLimit, bottomLimit) {

    if ($(window).scrollTop() > topLimit) {
        if ($(window).scrollTop() > bottomLimit) {
            $(rightPanel).css({
                position: 'absolute',
                top: '',
                left: '',
                bottom: '0',
                width: width.toString() + 'px'
            });
        } else {
            $(rightPanel).css({
                position: 'fixed',
                top: yPos.toString() + 'px',
                left: xPos.toString() + 'px',
                bottom: '',
                width: width.toString() + 'px'
            });
        }
    } else {
        $(rightPanel).css({
            position: '',
            top: '',
            left: '',
            bottom: '',
            width: ''
        });
    }
}

function rightPanelStickyScroll(name, rightPanel, leftPanel) {

    if ($(rightPanel).length && $(leftPanel).length) {

        resetRightPanelStickyScroll(name, rightPanel, leftPanel);

        var scrollEvent = 'scroll.' + name;

        var yPos = $('#page-header').height() + 0;

        var xPos = $(rightPanel).offset().left;
        var width = $(rightPanel).outerWidth() - 40;
        var height = $(rightPanel).outerHeight();

        var colHeight = $(rightPanel).outerHeight() > $(leftPanel).outerHeight() ?
            $(rightPanel).outerHeight() : $(leftPanel).outerHeight();

        var topLimit = $(rightPanel).offset().top - yPos;
        var bottomLimit = $(leftPanel).offset().top + colHeight - (yPos + height);

        $(rightPanel).parent().height(colHeight);
        $(leftPanel).parent().height(colHeight);

        update();

        $(window).on(scrollEvent, function () {
            update();
        });
    }
}

function update() {
    if ($(window).scrollTop() > topLimit) {
        if ($(window).scrollTop() > bottomLimit) {
            $(rightPanel).css({
                position: 'absolute',
                top: '',
                left: '',
                bottom: '0',
                width: width.toString() + 'px'
            });
        } else {
            $(rightPanel).css({
                position: 'fixed',
                top: yPos.toString() + 'px',
                left: xPos.toString() + 'px',
                bottom: '',
                width: width.toString() + 'px'
            });
        }
    } else {
        $(rightPanel).css({
            position: '',
            top: '',
            left: '',
            bottom: '',
            width: ''
        });
    }
}

function updateActiveNav() {
    var currentSectionName = currentSection();
    $("#nav a").removeClass('active is-active');
    $("#nav a[data-section*='" + currentSectionName + "']").closest('li').addClass('active is-active');
}

// Track anchor links having class=ga-tracked with GA events
$(document).on('click', 'a.ga-tracked', function (e) {

  if (baseUrl !== "//localhost") {
    var $this = $(this);
    var url = $this.attr('href');
    var event_category = $this.data('event-category') || "outbound";

    gtag('event', 'click', {
      'event_category': event_category,
      'event_label': url,
      'transport_type': 'beacon',
      'event_callback': function () { }
    });
  }
});

// Track form submissions having class=ga-tracked with GA events
$(document).on('submit', 'form.ga-tracked', function (e) {

  if (baseUrl !== "//localhost") {
    e.preventDefault();
    var theForm = $(this);
    var event_label = theForm.data('event-label') || "accommodation booking form";

    gtag('event', 'submit', {
      'event_category': 'form',
      'event_label': event_label,
      'transport_type': 'beacon',
      'event_callback': function () { theForm.submit(); }
    });
  }
});

function sendGTM(element) {
    let props = element.getAttributeNames().filter(attr => (attr.startsWith('data-gtm')));
    if(!props.length) return;
    let newDataLayer = {};
    props.forEach(attr => {
        newDataLayer[attr.slice(9)] = element.getAttribute(attr);
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(newDataLayer);
}

function gtmTracking() {
    $(document).on('click', '[data-click-gtm]', function (e) {
        sendGTM(e.currentTarget);
    });
}



